<template>
  <div class="language-selector">
    <a href="" @click.prevent="setLanguage('it')" :class="{ 'active': language == 'it' }">Italiano</a>
    <a href="" @click.prevent="setLanguage('en')" :class="{ 'active': language == 'en' }">English</a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  LANGUAGE_SET,
} from "@/store/actions.type";

export default {
  name: "LanguageSelector",
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters(["language"]),
  },
  methods: {
    setLanguage(language) {
      this.$store.dispatch(LANGUAGE_SET, language).then(() => {
        this.$i18n.locale = language;
        // this.$router.go();
        this.$emit('refreshData');
      });
    }
  },
  mounted() {
    this.$i18n.locale = this.language;
  }
};

</script>

<style lang="scss" scoped>
@import '../styles/global.scss';

.language-selector {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  a {
    display: block;
    margin: 0 1rem;
    color: var(--clr-blue-dark);
    font-size: 0.75rem;
    text-transform: uppercase;
    transition: color 300ms ease;

    &:hover {
      color: var(--clr-blue-light);
    }

    &.active {
      font-weight: 700;
    }
  }
}
</style>