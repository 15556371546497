import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_URL } from "@/common/config";

const ApiService = {

  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },

  setHeader() {
    // Vue.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Token ${JwtService.getToken()}`;
  },

  get(resource) {
    return Vue.axios.get(resource).catch(error => {
      throw new Error(`${error}`);
    });
  },

  post(resource, params) {
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    return Vue.axios.post(resource, params);
  },

  update(resource, params) {
    return Vue.axios.put(resource, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(resource, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`${error}`);
    });
  },

}

export default ApiService;