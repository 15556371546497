<template>
  <header class="app-header">
  </header>
</template>

<script>

export default {
  name: "AppHeader",
  data() {
    return {
      
    }
  },
  computed: {

  },
  methods: {

  }
};

</script>

<style lang="scss" scoped>
@import '../styles/global.scss';

.app-header {
  width: 100%;
  // height: rem(40px);
  height: 1rem;
  background-color: var(--clr-blue-light);
}
</style>