import axios from 'axios';
// import Swal from 'sweetalert2/dist/sweetalert2.js';

import router from '../router';
import store from '../store';

// import 'sweetalert2/dist/sweetalert2.css'

import {
  ERROR_SET
} from "../store/actions.type";

const ErrorService = {

  init() {
    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      console.log('service error report:', error);
      // console.log(error.response.data.error);

      store.dispatch(ERROR_SET, error.response.data.error).then(() => {
        router.push({ path: "/" });
      });
      
      // store.dispatch(ERROR_SET, error.response.data.error);
      // router.push({ path: "/" });

      // if(error.response.data) {
			// 	let errorMessage = '';
      //   if(error.response.data.message) {
      //     errorMessage += error.response.data.message;
      //   }
      //   let errorTitle = 'An error has occurred';
      //   if(error.response.data.title) {
      //     errorTitle = error.response.data.title;
      //   }
			// 	Swal.fire({
      //     title: errorTitle,
			// 		text: errorMessage,
			// 		icon: 'error',
      //     buttonsStyling: false,
      //     customClass: {
      //       confirmButton: 'btn btn-primary'
      //     }
      //   }).then(() => {
      //     location.reload();
      //     return false;
      //   })
			// }

      if(error.message) {
        return Promise.reject(error);
      }
    });
  }
}

export default ErrorService;