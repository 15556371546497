<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 65.6" width="300" class="">
    <g enable-background="new">
    <path fill="#092F34" d="M105.2,42.7c-1.3,0-2.5-0.4-3.7-1.2c-1.1-0.8-2-1.9-2.7-3.2s-1-2.7-1-4.2v-3.4c0-1.6,0.3-3,1-4.3
    s1.6-2.3,2.7-3.1c1.1-0.8,2.4-1.2,3.7-1.2c1.3,0,2.4,0.3,3.4,1c0.9,0.7,1.6,1.4,2.1,2.2V12.4c0-0.5,0.3-0.9,0.8-1.2s1.2-0.5,2-0.5
    s1.4,0.2,1.9,0.5s0.8,0.7,0.8,1.2v28.2c0,0.4-0.3,0.8-0.8,1.2s-1.1,0.5-1.9,0.5c-0.7,0-1.2-0.2-1.7-0.5c-0.5-0.4-0.7-0.8-0.7-1.2
    v-1.2c-0.4,0.9-1.2,1.6-2.2,2.3C107.7,42.4,106.5,42.7,105.2,42.7z M106.9,38c0.7,0,1.4-0.2,1.9-0.6c0.6-0.4,1-0.9,1.3-1.3
    c0.3-0.5,0.5-0.9,0.5-1.2v-4.2c0-0.8-0.2-1.5-0.6-2c-0.4-0.6-0.9-1-1.4-1.3c-0.6-0.3-1.2-0.5-1.7-0.5c-0.7,0-1.3,0.2-1.9,0.6
    s-1,0.8-1.3,1.4s-0.5,1.2-0.5,1.8v3.4c0,0.6,0.2,1.3,0.5,1.8c0.3,0.6,0.8,1.1,1.3,1.4C105.6,37.8,106.2,38,106.9,38z"></path>
    <path fill="#092F34" d="M123,17.9c-0.8,0-1.5-0.3-2.1-0.8s-0.9-1.2-0.9-1.8c0-0.7,0.3-1.4,0.9-1.9s1.3-0.8,2.1-0.8
    c0.8,0,1.5,0.3,2.1,0.8s0.9,1.1,0.9,1.9c0,0.7-0.3,1.3-0.9,1.8C124.5,17.7,123.8,17.9,123,17.9z M123,42.3c-0.8,0-1.4-0.2-1.9-0.5
    c-0.5-0.4-0.8-0.8-0.8-1.2V24.1c0-0.5,0.3-0.9,0.8-1.2c0.5-0.3,1.1-0.5,1.9-0.5s1.4,0.2,1.9,0.5s0.8,0.7,0.8,1.2v16.5
    c0,0.4-0.3,0.8-0.8,1.2C124.4,42.2,123.8,42.3,123,42.3z"></path>
    <path fill="#092F34" d="M137.8,51.6c-1.7,0-3.2-0.2-4.6-0.7c-1.4-0.5-2.5-1.1-3.4-2c-0.9-0.9-1.3-2-1.3-3.2c0-1.1,0.3-2,0.9-2.7
    c0.6-0.7,1.3-1.3,2.2-1.7c-0.5-0.3-1-0.6-1.3-1s-0.5-1-0.5-1.7c0-0.8,0.2-1.4,0.6-1.9c0.4-0.5,0.9-0.9,1.5-1.4
    c-0.8-0.6-1.5-1.4-1.9-2.3c-0.4-0.9-0.7-1.9-0.7-3v-0.9c0-1.4,0.3-2.6,1-3.6s1.6-1.8,2.9-2.4c1.2-0.6,2.7-0.9,4.3-0.9
    c0.9,0,1.8,0.1,2.6,0.3c0.8,0.2,1.5,0.5,2.2,0.9c0.5-1.1,0.9-1.9,1.3-2.3c0.4-0.4,0.8-0.6,1.2-0.6c0.7,0,1.2,0.2,1.6,0.6
    c0.4,0.4,0.6,0.8,0.6,1.1c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.5c-0.3,0.2-0.6,0.4-0.9,0.6c-0.4,0.2-0.7,0.6-1,1
    c0.5,0.6,0.9,1.2,1.1,1.9c0.2,0.7,0.4,1.4,0.4,2.2V30c0,1.3-0.4,2.5-1.1,3.6c-0.7,1-1.7,1.9-2.9,2.5c-1.3,0.6-2.7,0.9-4.3,0.9
    c-1,0-1.9-0.1-2.7-0.4c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.5,0.3,0.8,0.9,1c0.6,0.2,1.4,0.4,2.4,0.5
    s2,0.3,3,0.4c1.1,0.2,2.1,0.5,3,0.9c1,0.4,1.7,1,2.4,1.7c0.6,0.8,0.9,1.8,0.9,3.1c0,1.6-0.4,2.8-1.3,3.8c-0.9,1-2,1.7-3.4,2.1
    C140.8,51.4,139.3,51.6,137.8,51.6z M137.7,48.1c1.1,0,2-0.3,2.8-0.8s1.2-1.2,1.2-2.1c0-0.6-0.2-1.1-0.5-1.4s-0.8-0.6-1.4-0.8
    c-0.6-0.2-1.2-0.3-1.9-0.4c-0.7-0.1-1.3-0.2-2-0.3c-0.7,0.3-1.2,0.7-1.6,1.2c-0.4,0.5-0.6,1-0.6,1.7c0,0.9,0.4,1.7,1.2,2.2
    C135.6,47.9,136.6,48.1,137.7,48.1z M137.6,33.7c0.9,0,1.7-0.3,2.2-1s0.8-1.6,0.8-2.7v-0.9c0-1-0.3-1.9-0.8-2.6s-1.3-1-2.2-1
    c-1,0-1.7,0.4-2.2,1c-0.5,0.7-0.8,1.6-0.8,2.6v0.8c0,1.1,0.3,2,0.8,2.7C135.9,33.3,136.6,33.7,137.6,33.7z"></path>
    <path fill="#092F34" d="M152.4,17.9c-0.8,0-1.5-0.3-2.1-0.8s-0.9-1.2-0.9-1.8c0-0.7,0.3-1.4,0.9-1.9c0.6-0.5,1.3-0.8,2.1-0.8
    s1.5,0.3,2.1,0.8s0.9,1.1,0.9,1.9c0,0.7-0.3,1.3-0.9,1.8C153.9,17.7,153.2,17.9,152.4,17.9z M152.4,42.3c-0.8,0-1.4-0.2-1.9-0.5
    c-0.5-0.4-0.8-0.8-0.8-1.2V24.1c0-0.5,0.3-0.9,0.8-1.2c0.5-0.3,1.1-0.5,1.9-0.5c0.8,0,1.4,0.2,1.9,0.5s0.8,0.7,0.8,1.2v16.5
    c0,0.4-0.3,0.8-0.8,1.2C153.8,42.2,153.1,42.3,152.4,42.3z"></path>
    <path fill="#092F34" d="M167,42.3c-2.5,0-4.4-0.5-5.8-1.6c-1.3-1.1-2-2.8-2-5.2V15.2c0-0.5,0.3-0.9,0.9-1.2c0.6-0.3,1.2-0.5,1.8-0.5
    c0.7,0,1.3,0.2,1.9,0.5c0.6,0.3,0.9,0.7,0.9,1.2v7.2h5c0.4,0,0.8,0.2,1,0.6s0.4,0.9,0.4,1.4s-0.1,1-0.4,1.4c-0.2,0.4-0.6,0.6-1,0.6
    h-5v9.2c0,0.8,0.2,1.4,0.5,1.7s1,0.5,1.8,0.5h1.6c0.8,0,1.4,0.2,1.7,0.7c0.4,0.5,0.6,1,0.6,1.6s-0.2,1.1-0.6,1.6
    c-0.4,0.5-1,0.7-1.7,0.7L167,42.3L167,42.3z"></path>
    <path fill="#092F34" d="M178,42.7c-1.5,0-2.8-0.5-3.8-1.4c-1.1-0.9-1.6-2.3-1.6-4.2c0-1.6,0.4-2.8,1.2-3.7s2-1.5,3.4-1.9
    c1.5-0.4,3.2-0.5,5.1-0.5h1.7v-0.5c0-0.6-0.1-1.2-0.4-1.8c-0.2-0.6-0.6-1.1-1.1-1.5s-1.3-0.6-2.2-0.6c-1.1,0-1.9,0.1-2.5,0.3
    c-0.6,0.2-1,0.4-1.3,0.7c-0.3,0.2-0.6,0.3-1,0.3c-0.6,0-1-0.3-1.4-0.9s-0.5-1.2-0.5-1.8c0-0.6,0.4-1.1,1.1-1.6s1.6-0.8,2.6-1.1
    s2-0.4,3-0.4c2.2,0,3.9,0.4,5.2,1.2c1.3,0.8,2.3,1.8,2.9,3c0.6,1.2,0.9,2.6,0.9,4v10.3c0,0.5-0.3,0.9-0.8,1.2s-1.1,0.5-1.9,0.5
    c-0.7,0-1.3-0.2-1.7-0.5s-0.7-0.7-0.7-1.2v-1.2c-0.8,0.9-1.6,1.6-2.7,2.3C180.7,42.4,179.5,42.7,178,42.7z M180.2,38.7
    c0.6,0,1.2-0.2,1.8-0.6c0.6-0.4,1.1-0.9,1.5-1.5s0.6-1.3,0.6-1.9v-0.9h-0.6c-0.9,0-1.8,0.1-2.6,0.2c-0.8,0.1-1.5,0.4-2,0.7
    c-0.5,0.4-0.8,1-0.8,1.7c0,0.8,0.2,1.4,0.7,1.7C179,38.5,179.6,38.7,180.2,38.7z"></path>
    <path fill="#092F34" d="M196.6,42.3c-0.8,0-1.4-0.2-1.9-0.5c-0.5-0.4-0.8-0.8-0.8-1.2V12.4c0-0.5,0.3-0.9,0.8-1.2s1.1-0.5,1.9-0.5
    c0.8,0,1.4,0.2,1.9,0.5s0.8,0.7,0.8,1.2v28.2c0,0.4-0.3,0.8-0.8,1.2C198,42.2,197.3,42.3,196.6,42.3z"></path>
    </g>
    <g enable-background="new">
    <path fill="#092F34" d="M205.5,42.3c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.6v-18c0-0.3,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.2,0.7-0.2
    s0.6,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.6v2.1c0.5-0.8,1.2-1.5,2.1-2.2c0.9-0.7,2.1-1,3.5-1s2.7,0.4,3.8,1.2c1.1,0.8,1.9,1.8,2.4,3
    c0.7-1.4,1.7-2.5,2.8-3.2c1.2-0.7,2.3-1,3.5-1s2.3,0.3,3.4,0.9s1.9,1.5,2.6,2.6c0.7,1.1,1,2.5,1,4.2v11.5c0,0.2-0.1,0.4-0.3,0.6
    c-0.2,0.2-0.4,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.6V30c0-1.3-0.3-2.3-0.8-3.2c-0.5-0.9-1.2-1.5-2-1.9
    s-1.6-0.7-2.4-0.7c-0.9,0-1.7,0.2-2.5,0.7s-1.5,1.1-2,1.9s-0.8,1.8-0.8,2.9v11.8c0,0.3-0.1,0.5-0.3,0.6s-0.5,0.2-0.7,0.2
    s-0.5-0.1-0.7-0.2c-0.2-0.1-0.3-0.3-0.3-0.6V29.8c0-1.1-0.2-2.1-0.7-2.9s-1.1-1.5-1.8-2c-0.8-0.5-1.6-0.7-2.6-0.7
    c-0.9,0-1.7,0.2-2.5,0.7s-1.5,1.1-2,2s-0.8,1.9-0.8,3.1v11.5c0,0.2-0.1,0.4-0.3,0.6C206.1,42.3,205.8,42.3,205.5,42.3z"></path>
    <path fill="#092F34" d="M241.4,42.6c-1.6,0-3-0.5-4.1-1.4s-1.7-2.3-1.7-4.2c0-1.5,0.4-2.6,1.2-3.4c0.8-0.8,2-1.4,3.4-1.7
    c1.5-0.3,3.2-0.5,5.1-0.5h2.3v-1.1c0-1-0.2-1.9-0.5-2.9c-0.4-1-0.9-1.7-1.7-2.4s-1.8-0.9-3.1-0.9c-0.9,0-1.6,0.1-2.2,0.3
    c-0.6,0.2-1,0.4-1.4,0.6s-0.6,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.4s-0.3-0.5-0.3-0.7c0-0.3,0.3-0.7,0.8-0.9s1.2-0.5,2.1-0.7
    c0.8-0.2,1.7-0.3,2.5-0.3c1.8,0,3.3,0.4,4.3,1.2c1,0.8,1.8,1.8,2.3,3s0.7,2.5,0.7,3.9v11.1c0,0.2-0.1,0.4-0.3,0.6
    c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.6v-2.1c-0.7,0.8-1.6,1.5-2.6,2.2C244,42.3,242.8,42.6,241.4,42.6z
      M241.7,40.7c1,0,1.9-0.2,2.8-0.7c0.9-0.5,1.7-1.1,2.2-1.8s0.9-1.4,0.9-2v-3.1h-2.1c-1.4,0-2.7,0.1-3.9,0.2c-1.2,0.2-2.2,0.5-2.9,1
    c-0.8,0.5-1.1,1.4-1.1,2.6c0,1.3,0.4,2.3,1.2,2.9C239.6,40.4,240.6,40.7,241.7,40.7z"></path>
    <path fill="#092F34" d="M261.3,42.3c-2.1,0-3.7-0.5-4.7-1.4c-1-0.9-1.6-2.5-1.6-4.5V13.8c0-0.3,0.1-0.5,0.3-0.7s0.4-0.2,0.7-0.2
    c0.3,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.7v8.7h5.6c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.7c0,0.2-0.1,0.4-0.2,0.6
    c-0.2,0.2-0.3,0.3-0.6,0.3H257v12.1c0,1.5,0.3,2.5,1,3.1s1.8,0.9,3.3,0.9h1c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7
    s-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3h-1V42.3z"></path>
    <path fill="#092F34" d="M273.7,42.6c-1.8,0-3.3-0.3-4.4-1c-1.2-0.7-2-1.6-2.6-2.8c-0.6-1.2-0.9-2.5-0.9-4V30c0-1.5,0.3-2.8,0.8-4
    c0.6-1.2,1.4-2.1,2.6-2.8c1.2-0.7,2.7-1,4.5-1c1.1,0,2.1,0.1,3,0.4c0.9,0.3,1.5,0.6,2,1s0.7,0.8,0.7,1.1c0,0.1,0,0.3-0.1,0.5
    c-0.1,0.2-0.2,0.3-0.4,0.4c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.9-0.4c-0.3-0.3-0.8-0.6-1.3-0.8c-0.6-0.3-1.4-0.4-2.5-0.4
    c-1.9,0-3.4,0.5-4.4,1.6s-1.5,2.5-1.5,4.3v4.8c0,1.8,0.5,3.2,1.5,4.3c1,1,2.5,1.6,4.5,1.6c1.1,0,2-0.1,2.6-0.4
    c0.6-0.3,1.1-0.6,1.4-0.8c0.3-0.3,0.6-0.4,0.9-0.4c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.1,0.3,0.1,0.5
    c0,0.3-0.2,0.7-0.7,1.1c-0.5,0.4-1.2,0.8-2.1,1.1C276,42.4,274.9,42.6,273.7,42.6z"></path>
    <path fill="#092F34" d="M284.6,42.3c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.6V11.1c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.2,0.7-0.2
    s0.6,0.1,0.8,0.2c0.2,0.2,0.3,0.4,0.3,0.7v14.4c0.5-0.8,1.2-1.5,2.1-2.2c0.9-0.7,2.1-1,3.5-1c1.4,0,2.6,0.3,3.8,1
    c1.1,0.7,2,1.6,2.7,2.8c0.7,1.2,1,2.5,1,3.9v11.5c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.2-0.7,0.2s-0.4-0.1-0.7-0.2
    c-0.2-0.2-0.3-0.4-0.3-0.7V30c0-1-0.3-1.9-0.8-2.8s-1.2-1.6-2-2.1s-1.8-0.8-2.8-0.8c-0.9,0-1.8,0.2-2.6,0.7c-0.8,0.5-1.5,1.1-2,2
    s-0.8,1.9-0.8,3.1v11.5c0,0.2-0.1,0.4-0.3,0.6C285.2,42.3,284.9,42.3,284.6,42.3z"></path>
    </g>
    <g transform="translate(-390.882 -260.462)">
    <linearGradient id="T2" gradientUnits="userSpaceOnUse" x1="278.9419" y1="5.2253" x2="278.9419" y2="4.2259" gradientTransform="matrix(16.893 0 0 45.8207 -4296.7153 66.8681)">
    <stop offset="0" style="stop-color:#3BB2A0"></stop>
    <stop offset="1" style="stop-color:#85BC4C"></stop>
    </linearGradient>
    <path fill="url(#T2)" d="M423.2,300.2c-1.8,4.9-7.3,7.3-12.1,5.5c-1.6-0.6-3.1-1.7-4.1-3.1
    c1.5,0.5,3.2-0.3,3.7-1.8c0.1-0.3,0.2-0.6,0.2-1V267c0-3.6,2.9-6.5,6.5-6.5s6.5,2.9,6.5,6.5l0,0v30.5
    C423.8,298.4,423.6,299.3,423.2,300.2z"></path>

    <linearGradient id="T3" gradientUnits="userSpaceOnUse" x1="278.9404" y1="9.8162" x2="278.9404" y2="8.8164" gradientTransform="matrix(16.892 0 0 32.8028 -4259.811 -10.4983)">
    <stop offset="0" style="stop-color:#FB930B"></stop>
    <stop offset="1" style="stop-color:#E72A41"></stop>
    </linearGradient>
    <path fill="url(#T3)" d="M459.8,284.8c-1.8-4.9-7.3-7.3-12.1-5.5c-1.6,0.6-3.1,1.7-4.1,3.1
    c1.5-0.5,3.2,0.3,3.7,1.8c0.1,0.3,0.2,0.6,0.2,1V305c0,3.6,2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5v-17.4
    C460.4,286.6,460.2,285.7,459.8,284.8z"></path>

    <linearGradient id="T4" gradientUnits="userSpaceOnUse" x1="278.9362" y1="12.8623" x2="278.9362" y2="11.8623" gradientTransform="matrix(16.894 0 0 27.5988 -4242.397 -48.7125)">
    <stop offset="0" style="stop-color:#E72A41"></stop>
    <stop offset="1" style="stop-color:#B62134"></stop>
    </linearGradient>
    <path id="Tracciato_1077" fill="url(#T4)" d="M477.7,284.8c-1.8-4.9-7.3-7.3-12.1-5.5c-1.6,0.6-3.1,1.7-4.1,3.1
    c1.5-0.5,3.2,0.3,3.7,1.8c0.1,0.3,0.2,0.6,0.2,1v14.7c0,3.6,2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5v-12.2
    C478.3,286.6,478.1,285.6,477.7,284.8z"></path>

    <linearGradient id="T5" gradientUnits="userSpaceOnUse" x1="278.9288" y1="12.8633" x2="278.9288" y2="11.8633" gradientTransform="matrix(16.8931 0 0 27.5973 -4311.5225 -48.7185)">
    <stop offset="0" style="stop-color:#0E555D"></stop>
    <stop offset="1" style="stop-color:#3BB2A0"></stop>
    </linearGradient>
    <path fill="url(#T5)" d="M392.7,284.8c1.8-4.9,7.3-7.3,12.1-5.5c1.6,0.6,3.1,1.7,4.1,3.1c-1.5-0.5-3.2,0.3-3.7,1.8
    c-0.1,0.3-0.2,0.6-0.2,1v14.7c0,3.6-2.9,6.5-6.5,6.5s-6.5-2.9-6.5-6.5v-12.2C392.1,286.6,392.3,285.7,392.7,284.8z"></path>

    <linearGradient id="T6" gradientUnits="userSpaceOnUse" x1="278.9373" y1="7.6022" x2="278.9373" y2="6.6022" gradientTransform="matrix(16.894 0 0 38.0099 -4278.2163 27.7204)">
    <stop offset="0" style="stop-color:#85BC4C"></stop>
    <stop offset="1" style="stop-color:#FB930B"></stop>
    </linearGradient>
    <path fill="url(#T6)" d="M441.9,284.8c-1.8-4.9-7.3-7.3-12.1-5.5c-1.6,0.6-3.1,1.7-4.1,3.1
    c1.5-0.5,3.2,0.3,3.7,1.8c0.1,0.3,0.2,0.6,0.2,1v25.1c0,3.6,2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5v-22.7
    C442.5,286.6,442.3,285.6,441.9,284.8z"></path>
    </g>
    <g>
    <path fill="#092F34" d="M226.8,52.9c0.6,0.4,1,0.9,1.4,1.6c0.3,0.7,0.5,1.5,0.5,2.4c0,0.9-0.2,1.7-0.5,2.4s-0.8,1.2-1.4,1.6
    c-0.6,0.4-1.2,0.6-2,0.6c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.3-0.8-0.7-1.1-1.3v1.6h-2.7V49.5h2.7V54c0.2-0.5,0.6-1,1.1-1.3
    c0.5-0.3,1.1-0.5,1.8-0.5C225.5,52.3,226.2,52.5,226.8,52.9z M222.5,55.3c-0.4,0.4-0.6,0.9-0.6,1.6c0,0.7,0.2,1.2,0.6,1.6
    s0.8,0.6,1.4,0.6c0.6,0,1.1-0.2,1.4-0.6s0.5-0.9,0.5-1.6s-0.2-1.2-0.5-1.6c-0.4-0.4-0.8-0.6-1.4-0.6
    C223.3,54.7,222.9,54.9,222.5,55.3z"></path>
    <path fill="#092F34" d="M232.1,52.4l2.1,5.5l2-5.5h3l-5.6,13.2h-3l2.1-4.6l-3.7-8.6H232.1z"></path>
    <path fill="#092F34" d="M246.1,50v11.3h-2.7V50H246.1z"></path>
    <path fill="#092F34" d="M256.1,53.4c0.6,0.7,0.9,1.6,0.9,2.8v5.2h-2.7v-4.9c0-0.6-0.2-1.1-0.5-1.4s-0.7-0.5-1.3-0.5
    c-0.6,0-1,0.2-1.3,0.5c-0.3,0.4-0.5,0.9-0.5,1.5v4.7H248v-8.9h2.7v1.6c0.3-0.5,0.6-1,1.1-1.3c0.5-0.3,1.1-0.5,1.8-0.5
    C254.7,52.3,255.5,52.7,256.1,53.4z"></path>
    <path fill="#092F34" d="M267,50v2.2h-3.1v9.1h-2.8v-9.1h-3V50H267z"></path>
    <path fill="#092F34" d="M272.2,52.8c0.5-0.3,1.1-0.5,1.8-0.5v2.9h-0.8c-0.7,0-1.3,0.2-1.7,0.5c-0.4,0.3-0.6,0.8-0.6,1.6v4.1h-2.7v-8.9
    h2.7v1.7C271.2,53.6,271.6,53.1,272.2,52.8z"></path>
    <path fill="#092F34" d="M275.3,48.9c0.3-0.3,0.7-0.4,1.2-0.4c0.5,0,0.9,0.1,1.2,0.4c0.3,0.3,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,1
    c-0.3,0.3-0.7,0.4-1.2,0.4c-0.5,0-0.9-0.1-1.2-0.4c-0.3-0.3-0.4-0.6-0.4-1C274.8,49.5,275,49.2,275.3,48.9z M277.8,52.4v8.9h-2.7
    v-8.9H277.8z"></path>
    <path fill="#092F34" d="M287.3,52.9c0.6,0.4,1,0.9,1.4,1.6c0.3,0.7,0.5,1.5,0.5,2.4c0,0.9-0.2,1.7-0.5,2.4s-0.8,1.2-1.4,1.6
    c-0.6,0.4-1.2,0.6-2,0.6c-0.7,0-1.3-0.2-1.8-0.5c-0.5-0.3-0.8-0.7-1.1-1.3v1.6h-2.7V49.5h2.7V54c0.2-0.5,0.6-1,1.1-1.3
    c0.5-0.3,1.1-0.5,1.8-0.5C286,52.3,286.7,52.5,287.3,52.9z M283,55.3c-0.4,0.4-0.6,0.9-0.6,1.6c0,0.7,0.2,1.2,0.6,1.6
    s0.8,0.6,1.4,0.6c0.6,0,1.1-0.2,1.4-0.6s0.5-0.9,0.5-1.6s-0.2-1.2-0.5-1.6c-0.4-0.4-0.8-0.6-1.4-0.6
    C283.8,54.7,283.3,54.9,283,55.3z"></path>
    <path fill="#092F34" d="M299,57.4h-6.2c0,0.7,0.2,1.2,0.5,1.4c0.3,0.3,0.7,0.4,1.2,0.4c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.4-0.5,0.5-0.8
    h2.9c-0.1,0.6-0.4,1.2-0.8,1.7c-0.4,0.5-0.9,0.9-1.5,1.2c-0.6,0.3-1.3,0.4-2,0.4c-0.9,0-1.7-0.2-2.3-0.6c-0.7-0.4-1.2-0.9-1.6-1.6
    c-0.4-0.7-0.6-1.5-0.6-2.4c0-0.9,0.2-1.8,0.6-2.4c0.4-0.7,0.9-1.2,1.6-1.6s1.5-0.6,2.3-0.6c0.9,0,1.7,0.2,2.3,0.5
    c0.7,0.4,1.2,0.9,1.6,1.5s0.6,1.4,0.6,2.3C299,56.9,299,57.1,299,57.4z M295.8,54.9c-0.3-0.3-0.7-0.4-1.2-0.4
    c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.7-0.6,1.3h3.5C296.3,55.7,296.1,55.2,295.8,54.9z"></path>
    </g>
  </svg>
</template>

<script>

export default {
  name: "MainLogo",
  data() {
    return {
      
    }
  },
  computed: {

  },
  methods: {

  }
};

</script>

<style lang="scss" scoped>
@import '../styles/global.scss';
</style>