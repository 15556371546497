import ApiService from "@/common/api.service";

import axios from "axios";

import {
  SURVEY_GET,
  SURVEY_GET_FILTER,
  SURVEY_GET_MAP_ITALY
} from "./actions.type";

import {
  STATE_SET_SURVEY,
  STATE_SET_SURVEY_MAP_ITALY
} from "./mutations.type";

let state = {
  survey: []
};

const getters = {
  survey(state) {
    return state.survey;
  },
  mapItaly(state) {
    return state.mapItaly;
  }
};

const actions = {
  [SURVEY_GET](context, payload) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post(payload.token + "/", payload.filterData)
        .then(({ data }) => {
          context.commit(STATE_SET_SURVEY, data);
          resolve(data);
        })
    });
  },
  [SURVEY_GET_FILTER](context, payload) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post(payload.token + "/", payload.filterData)
        .then(({ data }) => {
          context.commit(STATE_SET_SURVEY, data);
          resolve(data);
        })
    });
  },
  [SURVEY_GET_MAP_ITALY](context) {
    return new Promise(resolve => {
      var instance = axios.create({
        baseURL: window.location.origin
      });
      instance.get("/maps/italy.json")
        .then(({ data }) => {
          context.commit(STATE_SET_SURVEY_MAP_ITALY, data);
          resolve(data);
        })
    });
  }
}

const mutations = {
  [STATE_SET_SURVEY](state, survey) {
    state.survey = survey;
  },
  [STATE_SET_SURVEY_MAP_ITALY](state, map) {
    state.mapItaly = map;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};