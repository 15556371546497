<template>
  <div class="home">
    <div class="home__inner">
      <div class="home__logo">
        <MainLogo />
      </div>
      <div class="home__error">
        {{ displayError }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainLogo from "@/components/MainLogo";

import {
  ERROR_PURGE
} from "@/store/actions.type";

export default {
  name: 'Home',
  components: {
    MainLogo
  },
  data() {
    return {
      displayError: '',
      isLoading: false
    };
  },
  computed: {
    ...mapGetters(["error"]),
  },
  methods: {

  },
  mounted() {
    this.displayError = this.error;
    this.$store.dispatch(ERROR_PURGE);
  }
}
</script>

<style lang="scss">
@import '../styles/global.scss';

.home {
  width: 100%;
  height: calc(100vh - 1rem);
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    text-align: center;
  }

  &__logo {
    margin-bottom: 3rem;
  }

  &__error {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}
</style>