<template>
  <div id="app">
    <AppHeader />
    <AppSide />
    <div class="app-main">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppSide from "@/components/AppSide";

export default {
  name: 'App',
  components: {
    AppHeader,
    AppSide
  },
  computed: {

  }
}
</script>

<style lang="scss">
@import 'styles/global.scss';

.app-main {
  background-color: var(--clr-gray-light);
}
</style>