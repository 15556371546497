import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

import survey from "./survey.module";
import error from "./error.module";
import language from "./language.module";
import { STORAGE } from "@/common/config";

Vue.use(Vuex);

const vuexPersisted = new createPersistedState({
  key: STORAGE,
  storage: window.sessionStorage,
  reducer (val) {
    return val
  }
});

const store = new Vuex.Store({
  modules: {
    survey,
    error,
    language
  },
  plugins: [vuexPersisted]
});

export default store;