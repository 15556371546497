<template>
  <aside class="app-side">
  </aside>
</template>

<script>
export default {
  name: "AppSide",
  data() {
    return {

    }
  },
  methods: {

  }
};

</script>

<style lang="scss" scoped>
@import '../styles/global.scss';

.app-side {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 98;
  padding-top: rem(40px);
  width: rem(40px);
  height: 100%;
  background-color: var(--clr-red);
}
</style>