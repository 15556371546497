import {
  LANGUAGE_SET
} from "./actions.type";

import {
  STATE_SET_LANGUAGE
} from "./mutations.type";

let state = {
  language: ''
};

const getters = {
  language(state) {
    return state.language;
  }
};

const actions = {
  [LANGUAGE_SET](context, language) {
    context.commit(STATE_SET_LANGUAGE, language);
  }
}

const mutations = {
  [STATE_SET_LANGUAGE](state, language) {
    state.language = language;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};