<template>
  <div :class="{ 'survey': true, 'is-loading': isLoading }">
    <b-container>
      <b-row class="justify-content-between align-items-center survey-pre-title text-center text-md-left">
        <b-col cols="12" md="auto" class="order-md-2 mb-3 mb-md-0">
          <MainLogo />
        </b-col>
        <b-col cols="12" md="auto" class="order-md-1">
          <p class="survey-pre-title__main">Gamified Survey Data Storytelling - Welcome</p>
        </b-col>
      </b-row>
      <div class="survey-header">
        <LanguageSelector @refreshData="getData"/>
        <div class="survey-header__inner">
          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton width="75%" class="mx-auto mb-3"></b-skeleton>
            </template>
            <h1 class="h3 survey-header__title" v-if="Object.keys(surveyObject).length != 0 && surveyObject.survey_details">{{ surveyObject.survey_details.title }}</h1>
          </b-skeleton-wrapper>
          <b-row class="justify-content-center">
            <b-col cols="12" sm="6" md="4" class="mb-2 mb-md-0">
              <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                  <div class="survey-header-block">
                    <b-skeleton width="50%" class="mx-auto"></b-skeleton>
                    <b-skeleton type="button" class="mx-auto"></b-skeleton>
                  </div>
                </template>
                <div class="survey-header-block" v-if="Object.keys(surveyObject).length != 0 && surveyObject.survey_details">
                  <p class="survey-header-block__title">{{ $t('survey.header.total_responders') }}</p>
                  <p class="survey-header-block__value">{{ surveyObject.survey_details.responders_number }}</p>
                </div>
              </b-skeleton-wrapper>
            </b-col>
            <b-col cols="12" sm="6" md="4">
              <b-skeleton-wrapper :loading="isLoading">
                <template #loading>
                  <div class="survey-header-block">
                    <b-skeleton width="50%" class="mx-auto"></b-skeleton>
                    <b-skeleton type="button" class="mx-auto"></b-skeleton>
                  </div>
                </template>
                <div class="survey-header-block" v-if="Object.keys(surveyObject).length != 0 && surveyObject.survey_details">
                  <p class="survey-header-block__title">{{ $t('survey.header.last_update') }}</p>
                  <p class="survey-header-block__value">{{ surveyObject.survey_details.last_date }}</p>
                </div>
              </b-skeleton-wrapper>
            </b-col>
          </b-row>
          <template v-if="surveyObject.hasOwnProperty('registration_details') && !isLoading">
            <div v-if="!showFilters" class="survey-filters-show">
              <a href="#" @click.prevent="showFilters = !showFilters">
                <span>{{ $t('survey.filters.title') }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.97 384.97"><path d="M12.03,84.212h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03 C5.39,60.152,0,65.541,0,72.182C0,78.823,5.39,84.212,12.03,84.212z"/><path d="M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12 03 S379.58,180.455,372.939,180.455z"/><path d="M372.939,300.758H12.03c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h360.909 c6.641,0,12.03-5.39,12.03-12.03C384.97,306.147,379.58,300.758,372.939,300.758z"/></svg>
              </a>
            </div>
            <b-form @submit.prevent="getData" class="survey-filters" v-if="showFilters">
              <a href="#" class="survey-filters__close" @click.prevent="showFilters = !showFilters">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 371.23 371.23"><polygon points="371.23,21.213 350.018,0 185.615,164.402 21.213,0 0,21.213 164.402,185.615 0,350.018 21.213,371.23 185.615,206.828 350.018,371.23 371.23,350.018 206.828,185.615 "/></svg>
              </a>
              <b-row class="survey-filters__options">
                <b-col>
                  <p class="survey-filters__title">{{ $t('survey.filters.gender') }}</p>
                  <ul>
                    <li
                      v-for="(gender, index) in filters.genders"
                      :key="index"
                    >
                      <b-form-checkbox
                        v-model="currentFilters.genders"
                        :value="gender"
                      >
                        {{ gender }}
                      </b-form-checkbox>
                    </li>
                  </ul>
                </b-col>
                <b-col>
                  <p class="survey-filters__title">{{ $t('survey.filters.generation') }}</p>
                  <ul>
                    <li
                      v-for="(generation, index) in filters.generations"
                      :key="index"
                    >
                      <b-form-checkbox
                        v-model="currentFilters.generations"
                        :value="generation"
                      >
                        {{ generation }}
                      </b-form-checkbox>
                    </li>
                  </ul>
                </b-col>
                <b-col>
                  <p class="survey-filters__title">{{ $t('survey.filters.geolocalization') }}</p>
                  <ul>
                    <li
                      v-for="(geolocalization, index) in filters.geolocalizations"
                      :key="index"
                    >
                      <b-form-checkbox
                        v-model="currentFilters.geolocalizations"
                        :value="geolocalization"
                      >
                        {{ geolocalization }}
                      </b-form-checkbox>
                    </li>
                  </ul>
                </b-col>
              </b-row>
              <div class="survey-filters__actions">
                <b-button type="submit" variant="primary">{{ $t('survey.filters.filter') }}</b-button>
              </div>
            </b-form>
            <b-skeleton-wrapper :loading="isLoading">
              <template #loading>
                <b-row class="my-4">
                  <b-col cols="12" lg="6">
                    <b-row>
                      <b-col lg="8">
                        <b-skeleton-img></b-skeleton-img>
                      </b-col>
                      <b-col lg="4">
                        <b-skeleton width="45%"></b-skeleton>
                        <b-skeleton width="35%"></b-skeleton>
                        <b-skeleton width="40%"></b-skeleton>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-row>
                      <b-col lg="8">
                        <b-skeleton-img></b-skeleton-img>
                      </b-col>
                      <b-col lg="4">
                        <b-skeleton width="45%"></b-skeleton>
                        <b-skeleton width="35%"></b-skeleton>
                        <b-skeleton width="40%"></b-skeleton>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col lg="8">
                    <b-skeleton-img></b-skeleton-img>
                  </b-col>
                  <b-col lg="4">
                    <b-skeleton width="45%"></b-skeleton>
                    <b-skeleton width="35%"></b-skeleton>
                    <b-skeleton width="40%"></b-skeleton>
                  </b-col>
                </b-row>
              </template>
              <template v-if="surveyObject.registration_details.users_number > 0">
                <b-row>
                  <b-col cols="12" lg="6">
                    <p class="chart-title">{{ $t('survey.details.gender') }}</p>
                    <p class="chart-users">{{ $t('survey.details.total_responders') }}: {{ surveyObject.registration_details.users_number }}</p>
                    <b-row>
                      <b-col lg="8">
                        <v-chart class="chart chart--condensed" :option="getGendersChartData()" :autoresize="true" />
                      </b-col>
                      <b-col lg="4">
                        <ul class="legend">
                          <li
                            v-for="(data, index) in surveyObject.registration_details.genders"
                            :key="index"
                            class="row"
                          >
                            <div class="col-auto p-0">
                              <div
                                class="legend__color"
                                :data-color="defaultColors[index]"
                                :style="[
                                  data.value > 0 ? {
                                    'background-color': defaultColors[index]
                                  } : {
                                    'background-color': '#d3d3d3'
                                  }
                                ]"
                              ></div>
                            </div>
                            <div class="col p-0">
                              <p>{{ data.name }}</p>
                            </div>
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <p class="chart-title">{{ $t('survey.filters.generation') }}</p>
                    <p class="chart-users">{{ $t('survey.details.total_responders') }}: {{ surveyObject.registration_details.users_number }}</p>
                    <b-row>
                      <b-col lg="8">
                        <v-chart class="chart chart--condensed" :option="getGenerationsChartData()" :autoresize="true" />
                      </b-col>
                      <b-col lg="4">
                        <ul class="legend">
                          <li
                            v-for="(data, index) in surveyObject.registration_details.generations"
                            :key="index"
                            class="row"
                          >
                            <div class="col-auto p-0">
                              <div
                                class="legend__color"
                                :data-color="defaultColors[index]"
                                :style="[
                                  data.value > 0 ? {
                                    'background-color': defaultColors[index]
                                  } : {
                                    'background-color': '#d3d3d3'
                                  }
                                ]"
                              ></div>
                            </div>
                            <div class="col p-0">
                              <p>{{ data.name }}</p>
                            </div>
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col col="12" lg="12">
                    <p class="chart-title">{{ $t('survey.filters.geolocalization') }}</p>
                    <p class="chart-users">{{ $t('survey.details.total_responders') }}: {{ surveyObject.registration_details.users_number }}</p>
                  </b-col>
                  <b-col lg="8">
                    <v-chart class="chart" :option="getGeolocalizationsChatData()" :autoresize="true" />
                  </b-col>
                  <b-col lg="4">
                    <ul class="legend">
                      <li
                        v-for="(data, index) in surveyObject.registration_details.geolocalizations"
                        :key="index"
                        class="row"
                      >
                        <div class="col-auto p-0">
                          <div
                            class="legend__color"
                            :data-color="defaultColors[index]"
                            :style="[
                              data.value > 0 ? {
                                'background-color': defaultColors[index]
                              } : {
                                'background-color': '#d3d3d3'
                              }
                            ]"
                          ></div>
                        </div>
                        <div class="col p-0">
                          <p>{{ data.name }}</p>
                        </div>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </template>
            </b-skeleton-wrapper>
          </template>
        </div>
      </div>
      <template v-if="surveyObject.error">
        <div class="text-center mt-4">
          <p>{{ surveyObject.error }}</p>
        </div>
      </template>
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="survey-content survey-content--skeleton">
            <b-skeleton width="50%" class="mx-auto"></b-skeleton>
            <b-skeleton width="60%" class="mx-auto"></b-skeleton>
            <b-skeleton width="45%" class="mx-auto mb-3"></b-skeleton>
            <b-skeleton width="20%" class="mx-auto"></b-skeleton>
            <b-skeleton width="15%" class="mx-auto mb-5"></b-skeleton>
            <b-row>
              <b-col lg="8">
                <b-skeleton-img></b-skeleton-img>
              </b-col>
              <b-col lg="4">
                <b-skeleton width="45%"></b-skeleton>
                <b-skeleton width="35%"></b-skeleton>
                <b-skeleton width="40%"></b-skeleton>
                <b-skeleton width="20%"></b-skeleton>
              </b-col>
            </b-row>
          </div>
        </template>
        <div class="survey-content" v-if="Object.keys(surveyObject).length != 0 && surveyObject.survey_data">
          <div
            class="question"
            v-for="(question, index) in surveyObject.survey_data"
            :key="index"
          >
            <b-row class="justify-content-center">
              <b-col lg="8">
                <template v-if="question.error">
                  <div class="question-title">
                    <p class="question-title__number">{{ $t('survey.question.question') }} <strong>#{{ question.id }}</strong></p>
                    <h4 class="question-title__main">{{ question.question }}</h4>
                    <p class="question-title__navigation">{{ question.question.no_results }}</p>
                  </div>
                </template>
                <template v-else>
                  <div class="question-title">
                    <p class="question-title__number">{{ $t('survey.question.question') }} <strong>#{{ question.question.id }}</strong></p>
                    <h4 class="question-title__main">{{ question.question.question }}</h4>
                    <p class="question-title__navigation"><template v-if="question.question.previous_question != 0">{{ $t('survey.question.previous') }}: <strong>#{{ question.question.previous_question }}</strong></template><template v-if="question.question.previous_question != 0 && question.question.next_question != 0"> | </template><template v-if="question.question.next_question != 0">{{ $t('survey.question.next') }} <strong>{{ formatNextQuestions(question.question.next_question) }}</strong></template></p>
                    <p class="question-title__answered">{{ $t('survey.question.total_responders') }}: {{ question.question.responders_number }}</p>
                  </div>
                </template>
              </b-col>
            </b-row>
            <b-row v-if="!question.error">
              <b-col lg="8">
                <v-chart class="chart" :option="fixChartData(question.chart_type, question.chart)" :autoresize="true" />
              </b-col>
              <b-col lg="4">
                <ul class="legend">
                  <li
                    v-for="(data, index) in question.chart.series[0].data"
                    :key="index"
                    class="row"
                  >
                    <div class="col-auto p-0">
                      <div
                        class="legend__color"
                        data-color="#25c8b4"
                        :style="{ 'background-color': data.itemStyle.color }"
                      ></div>
                    </div>
                    <div class="col p-0">
                      <p>{{ data.name }}</p>
                    </div>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-skeleton-wrapper>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainLogo from "@/components/MainLogo";
import LanguageSelector from "@/components/LanguageSelector";

import {
  LANGUAGE_SET,
  SURVEY_GET,
  SURVEY_GET_FILTER
} from "@/store/actions.type";

export default {
  name: 'Survey',
  props: ['token'],
  components: {
    MainLogo,
    LanguageSelector
  },
  data() {
    return {
      isLoading: true,
      mapLoaded: false,
      surveyObject: {},
      defaultColors: [
        '#136773',
        '#25c8b4',
        '#81dc64',
        '#fba622'
      ],
      storedToken: null,
      showFilters: false,
      filters: {
        generations: [],
        genders: [],
        geolocalizations: []
      },
      currentFilters: {
        generations: [],
        genders: [],
        geolocalizations: []
      }
    };
  },
  computed: {
    ...mapGetters(["survey", "language"]),
  },
  methods: {
    getData() {
      this.isLoading = true;
      let payload = {
        token: this.token,
        filterData: {
          language_code: this.language
        }
      }
      let dispatchAction = SURVEY_GET;
      if(this.currentFilters.genders.length > 0) {
        payload.filterData.genders = [];
        this.currentFilters.genders.forEach((gender) => {
          payload.filterData.genders.push(gender);
          // payload.filterData.append('genders', gender);
          dispatchAction = SURVEY_GET_FILTER;
        });
      }
      if(this.currentFilters.generations.length > 0) {
        payload.filterData.generations = [];
        this.currentFilters.generations.forEach((generation) => {
          payload.filterData.generations.push(generation);
          // payload.filterData.append('generations', generation);
          dispatchAction = SURVEY_GET_FILTER;
        });
      }
      if(this.currentFilters.geolocalizations.length > 0) {
        payload.filterData.geolocalizations = [];
        this.currentFilters.geolocalizations.forEach((geolocalization) => {
          payload.filterData.geolocalizations.push(geolocalization);
          // payload.filterData.append('geolocalizations', geolocalization);
          dispatchAction = SURVEY_GET_FILTER;
        });
      }
      this.$store.dispatch(dispatchAction, payload).then(() => {
        this.surveyObject = this.survey;
        this.isLoading = false;
        if(Object.prototype.hasOwnProperty.call(this.surveyObject, 'registration_details')) {
          // this.$store.dispatch(SURVEY_GET_MAP_ITALY).then(() => {
          //   this.mapLoaded = true;
          // });
          if(this.filters.generations.length === 0) {
            this.saveFilters();
          }
        }
      });
    },
    formatNextQuestions(nextQuestions) {
      let questionString = '';
      nextQuestions.forEach((question, index) => {
        questionString += '#' + question;
        if(index !== nextQuestions.length - 1) {
          questionString += ' e ';
        }
      });
      return questionString
    },
    fixChartData(type, chartData) {
      if(type == 'donut') {
        chartData.series.forEach(serie => {
          if(Object.prototype.hasOwnProperty.call(serie, 'label')) {
            serie.label.formatter = function(d) {
              return parseFloat(d.percent).toFixed(1) + "%";
            }
          }
          serie.data.forEach(singleData => {
            if(Object.prototype.hasOwnProperty.call(singleData, 'label')) {
              singleData.label.formatter = function(d) {
                return parseFloat(d.percent).toFixed(1) + "%";
              }
            }
          }); 
        });
        chartData.tooltip.formatter = function(params) {
          return params.name + ' : ' + params.value + ' (' + parseFloat(params.percent).toFixed(1) + '%)';
        }
      } else {
        if(chartData.tooltip) {
          chartData.tooltip.formatter = function(params) {
            return params[0].name + ': ' + params[0].value;
          }
        }
      }
      if(type == 'vertical barchart' || type == 'horizontal barchart' || type == 'vertical barchart with scala') {
        chartData.tooltip.formatter = function(params) {
          return params[0].name + ' : ' + params[0].value + ' (' + parseFloat(params[0].data.percent).toFixed(1) + '%)';
        }
        chartData.label = {
          show: true,
          position: 'outside',
          formatter: function(params) {
            let returnValue = '';
            if(Object.prototype.hasOwnProperty.call(params, 'data')) {
              if(Object.prototype.hasOwnProperty.call(params.data, 'percent')) {
                returnValue = parseFloat(params.data.percent).toFixed(1) + '%';
              }
            }
            return returnValue
          }
        }
      }
      if(type == 'horizintal barchart' || type == 'horizontal barchart') {
        chartData.yAxis.data.forEach((data, index) => {
          chartData.yAxis.data[index] = '';
        });
        chartData.xAxis.axisLabel = {
          minInterval: 1
        }
      }
      if(type == 'scatter plot' || type == 'vertical barchart with scala') {
        chartData.xAxis.axisLabel = {
          minInterval: 1
        }
      }
      if(type == 'scatter plot') {
        chartData.xAxis.offset = 10;
        chartData.xAxis.axisLabel.align = 'center';
        chartData.xAxis.data.forEach((data, index) => {
          chartData.xAxis.data[index] = chartData.xAxis.data[index].replace("’", "'");
          chartData.xAxis.data[index] = chartData.xAxis.data[index].replace(" d'accordo", "\r\nd'accordo");
        });
        chartData.tooltip = {
          trigger: 'axis',
          formatter: function(params) {
            let fixedName = params[0].name.replace("’", "'");
            return fixedName + ' : ' + params[0].value + ' (' + parseFloat(params[0].data.percent).toFixed(1) + '%)';
          }
        }
      }

      return chartData
    },
    getGendersChartData() {
      let genders = [];
      var valuesTotal = 0;
      this.surveyObject.registration_details.genders.forEach((gender) => {
        valuesTotal += gender.value;
      });
      this.surveyObject.registration_details.genders.forEach((gender, index) => {
        let color = '#d3d3d3';
        if (gender.value > 0) {
          color = this.defaultColors[index];
        }
        let genderObject = {
          value: gender.value,
          name: gender.name,
          itemStyle: {
            color: color
          }
        }
        if((gender.value * 100 / valuesTotal) < 10) {
          genderObject.label = {
            position: "outside",
            show: true
          }
          genderObject.labelLine = {
            show: true
          }
        } else {
          genderObject.label = {
            position: "inside",
            show: true
          }
        }
        genders.push(genderObject);
      });
      let chartData = {
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return params.name + ' : ' + params.value + ' (' + parseFloat(params.percent).toFixed(1) + '%)'
          },
          // formatter: "{b} : {c} ({d}%)"
        },
        series: [
          {
            type: "pie",
            radius: ["40%","70%"],
            avoidLabelOverlap: false,
            label: {
              position: "inner",
              fontSize :14,
              formatter: function(d) {
                return parseFloat(d.percent).toFixed(1) + "%";
              }
            },
            labelLine: {
              show: false
            },
            data: genders
          }
        ]
      }
      return chartData
    },
    getGenerationsChartData() {
      let generations = [];
      var valuesTotal = 0;
      this.surveyObject.registration_details.generations.forEach((generation) => {
        valuesTotal += generation.value;
      });
      this.surveyObject.registration_details.generations.forEach((generation, index) => {
        let color = '#d3d3d3';
        if (generation.value > 0) {
          color = this.defaultColors[index];
        }
        let generationObject = {
          value: generation.value,
          name: generation.name,
          itemStyle: {
            color: color
          }
        }
        if((generation.value * 100 / valuesTotal) < 10) {
          generationObject.label = {
            position: "outside",
            show: true,
          }
          generationObject.labelLine = {
            show: true,
            lineStyle: {
              color: color
            }
          }
        } else {
          generationObject.label = {
            position: "inside",
            show: true,
          }
        }
        generations.push(generationObject);
      });
      let chartData = {
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            return params.name + ' : ' + params.value + ' (' + parseFloat(params.percent).toFixed(1) + '%)'
          },
          // formatter: "{b} : {c} ({d}%)"
        },
        series: [
          {
            type: "pie",
            radius: ["40%","70%"],
            avoidLabelOverlap: false,
            label: {
              fontSize: 14,
              formatter: function(d) {
                return parseFloat(d.percent).toFixed(1) + "%";
              }
            },
            labelLine: {
              show: false
            },
            data: generations
          }
        ]
      }
      return chartData
    },
    getGeolocalizationsChatData() {
      let geolocalizations = [];
      let valuesTotal = 0;
      this.surveyObject.registration_details.geolocalizations.forEach((geolocalization, index) => {
        let color = '#d3d3d3';
        if (geolocalization.value > 0) {
          color = this.defaultColors[index];
        }
        let correctName = geolocalization.name;
        if(geolocalization.name == "Northeast") {
          correctName = 'Nord-est';
        }
        if(geolocalization.name == "Northwest") {
          correctName = 'Nord-ovest';
        }
        if(geolocalization.name == "Center") {
          correctName = 'Centro';
        }
        if(geolocalization.name == "South") {
          correctName = 'Sud';
        }
        let geolocalizationObject = {
          value: geolocalization.value,
          oldName: geolocalization.name,
          name: correctName,
          itemStyle: {
            areaColor: color
          }
        }
        geolocalizations.push(geolocalizationObject);
        valuesTotal += geolocalization.value;
      });
      let chartData = {
        tooltip: {
          trigger: "item",
          // formatter: "{b}: {c}"
          formatter: function(params) {
            let percent = params.data.value * 100 / valuesTotal;
            return params.data.oldName + ' : ' + params.data.value + ' (' + parseFloat(percent).toFixed(1) + '%)';
          }
        },
        series: [
          {
            type: 'map',
            colorBy: 'data',
            roam: false,
            map: 'italy',
            label: {
              show: true,
              formatter: function(params) {
                let percent = params.data.value * 100 / valuesTotal;
                return parseFloat(percent).toFixed(1) + '%';
              }
            },
            emphasis: {
              disabled: true,
            },
            select: {
              disabled: true
            },
            data: geolocalizations
          }
        ]
      }
      return chartData
    },
    resetFilters() {
      this.filters = {
        generations: [],
        genders: [],
        geolocalizations: []
      };
      this.currentFilters = {
        generations: [],
        genders: [],
        geolocalizations: []
      }
    },
    saveFilters() {
      this.surveyObject.registration_details.generations.forEach((generation) => { 
        this.filters.generations.push(generation.name);
      });
      this.surveyObject.registration_details.genders.forEach((gender) => { 
        this.filters.genders.push(gender.name);
      });
      this.surveyObject.registration_details.geolocalizations.forEach((geolocalization) => { 
        this.filters.geolocalizations.push(geolocalization.name);
      });
    }
  },
  mounted() {
    if(!this.language) {
      this.$store.dispatch(LANGUAGE_SET, 'it');
      this.$i18n.locale = 'it';
    }
    if (this.token) {
      this.getData();
      if(this.token != this.storedToken) {
        this.resetFilters();
      }
      this.storedToken = this.token;
    }
  }
}
</script>

<style lang="scss">
@import '../styles/global.scss';

.survey {
  min-height: calc(100vh - 1rem);

  &.is-loading {
    cursor: wait;
  }
}

.survey-pre-title {
  padding: 1.5rem 0;

  &__main {
    color: var(--clr-red);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 1.25rem;
  }
}

.survey-header {
  text-align: center;
  color: var(--clr-blue-dark);

  &__title {
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  &:after,
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0.5rem;
    background-color: var(--clr-blue-light);
    border-radius: 0.15rem;
  }

  &__inner {
    padding: 2rem 0;
  }
}

.survey-header-block {
  text-align: center;
  padding: 0.75rem 1.5rem;
  border: 2px solid var(--clr-blue-dark);
  border-radius: 0.25rem;

  &__title {
    text-transform: uppercase;
    color: var(--clr-blue-dark);
    font-weight: 700;
    margin-bottom: 0.25rem;
  }

  &__value {
    background-color: var(--clr-blue-dark);
    color: #fff;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    font-weight: 700;
    border-radius: 0.25rem;
    margin-bottom: 0;
  }
}

.survey-filters-show {
  padding: 1.5rem 0;
  
  a {
    display: inline-flex;
    align-items: center;
    color: var(--clr-blue-dark);
    text-transform: uppercase;
    font-weight: 700;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--clr-blue-dark);
      margin-left: 1rem;
      transition: fill 300ms ease;
    }

    &:hover {
      color: var(--clr-blue-light);

      svg {
        fill: var(--clr-blue-light);
      }
    }
  }
}

.survey-filters {
  margin-top: 1.5rem;
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.5rem;
  position: relative;

  &__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1rem;
    height: 1rem;
    z-index: 1;
    display: block;

    svg {
      width: 1rem;
      height: 1rem;
      fill: var(--clr-blue-dark);
      transition: fill 300ms ease;
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: var(--clr-blue-light);
      }
    }
  }

  &__title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: left;
  }

  &__options {
    margin-bottom: 1.5rem;

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        text-align: left;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: var(--clr-blue-light);
      background-color: var(--clr-blue-light);
      box-shadow: none;
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
      border-color: var(--clr-blue-light);
      box-shadow: none;
    }
  }

  &__actions {
    text-align: center;
  }  
}

.survey-content {

  &--skeleton {
    padding: 5rem 0;
  }
}

.chart-title {
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 2rem;
}

.chart-users {
  text-align: center;
  font-size: 0.875rem;
}

.question {
  text-align: center;
  margin-bottom: 3rem;

  &:first-child {
    margin-top: 3rem;
  }
}

.question-title {
  text-align: center;
  margin-bottom: 0;

  &__number {
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }

  &__main {
    margin-bottom: 0.75rem;
    font-size: 1.2rem;
    font-weight: 700;
  }

  &__navigation {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 0.75rem;
  }

  &__answered {
    font-size: 0.75rem;
    margin-bottom: 0;
  }  
}

.chart {
  // height: 30rem;
  min-height: 20rem;
  // width: 100%;

  @include respond-to('md') {
    min-height: 30rem;
  }

  &--condensed {
    min-height: 20rem;
  }
}

.legend {
  text-align: left;

  @include respond-to('lg') {
    margin-top: 3rem;
  }
  
  li {
    margin-bottom: 0.375rem;
  }

  p {
    margin-bottom: 0;
    line-height: 1.4;
  }

  &__color {
    display: block;
    width: 1rem;
    height: 0.5rem;
    border-radius: 0.125rem;
    margin-top: 0.5rem;
    background-color: #000;
    margin-right: 0.75rem;
  }
}
</style>