import {
  ERROR_SET,
  ERROR_PURGE
} from "./actions.type";

import {
  STATE_SET_ERROR,
  STATE_PURGE_ERROR
} from "./mutations.type";

let state = {
  error: ''
};

const getters = {
  error(state) {
    return state.error;
  }
};

const actions = {
  [ERROR_SET](context, error) {
    context.commit(STATE_SET_ERROR, error);
  },
  [ERROR_PURGE](context) {
    context.commit(STATE_PURGE_ERROR);
  }
}

const mutations = {
  [STATE_SET_ERROR](state, error) {
    state.error = error;
  },
  [STATE_PURGE_ERROR](state) {
    state.error = '';
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};